
import ServicesNote from '@/components/page_blocks/ServicesNote'
import ConfidentialityV1 from '@/components/page_blocks/ConfidentialityV1'

export default {
  name: 'SequencingSanger',
  components: {
    ConfidentialityV1,
    ServicesNote
  },
  scrollToTop: true,
  layout: 'main/Index',
  head() {
    return {
      title: 'Секвенирование ДНК по Сэнгеру',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Секвенированию плазмид и ПЦР-фрагментов проводится на автоматическом секвенаторе.' +
            ' Регулярно получаемая дальность прочтения - 500-600 нуклеотидов.' +
            ' При соблюдении оптимальных условий возможно прочтение до 800 нуклеотидов за прогон.'
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: 'секвенирование, Сэнгер, сиквенс'
        }
      ]
    }
  },
  data() {
    return {
      details1Expanded: false,
    }
  }
}

