import { render, staticRenderFns } from "./CES1.vue?vue&type=template&id=15f9cd2a&scoped=true"
import script from "./CES1.vue?vue&type=script&lang=js"
export * from "./CES1.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15f9cd2a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BuyProductArea: require('/var/www/localhost/evrogen.ru/components/BuyProductArea.vue').default})
